<style scoped>
#container {
  height: 100vh;
}
.diagonal-box {
  position: relative;
  background-image: linear-gradient(210deg, #2bd9eb 50%, #043561 50%);
}
h1, p {
  word-break: break-word;
}
</style>
<template>
  <div id="container" class="diagonal-box d-flex">
    <div v-if="$vuetify.breakpoint.mdAndUp" class="flex-grow-1 d-flex align-center justify-center">
      <v-card flat class="transparent white--text" max-width="600">
        <v-card-title>
          <h1 class="text-h3">
            <b>Telescope</b> Connects
            <br> <u>Attorneys</u> With
            <br> <u>Healthcare Clinics</u>.
          </h1>
        </v-card-title>
        <v-card-text class="white--text text-subtitle-1">
          <ul>
            <li>INSTANT UPDATES on patient progress</li>
            <li>Up to date patient BILLING ESTIMATES</li>
            <li>Easy DOCUMENT MANAGEMENT and e-signatures</li>
          </ul>
        </v-card-text>
      </v-card>
    </div>

    <div class="flex-grow-1 d-flex align-center justify-center">
      <v-card class="px-2 py-6 pa-md-12" max-width="675">
        <v-card-title>
          <h1 class="text-h5" v-if="$route.query.token">You have been invited to <span class="font-weight-bold">Telescope</span></h1>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="formValid">
            <template>
              <v-text-field
                v-model="patient.full_name"
                filled dense :rules="[rules.required]"
                label="* Your Full Name" />
              <v-text-field
                v-model="patient.phone"
                type="text"
                label="* Phone"
                filled dense
                :rules="[rules.phone]"
                v-mask="'(###) ###-####'"/>
              <DatePicker
                ref="datepicker"
                :date="patient.dob"
                :default-to-today="false"
                label="* Date of Birth"
                @date-change="setDob"
                :dateValid="dobValid"
              />
            </template>
          </v-form>
        </v-card-text>
        <v-card-actions class="flex-column">
          <v-btn :loading="loading" block color="primary" @click="verifyInformation">Verify</v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>
<script>

import event, { Events } from '../event'
import RulesMixin from '../mixins/RulesMixin'
import DatePicker from '@/components/PatientIntake/DatePicker'

export default {
  name: 'PatientInviteVerification',
  mixins: [RulesMixin],
  components: { DatePicker },
  data: () => ({
    formValid: true,
    loading: false,
    dobValid: true,
    patient: {
      full_name: '',
      phone: '',
      dob: ''
    }
  }),
  mounted () {
    this.getFullName()
  },
  methods: {
    setDob (value) {
      this.patient.dob = value
    },
    validateDob () {
      this.dobValid = true
      if (!this.patient.dob) {
        this.dobValid = 'Required'
      }
    },
    verifyInformation () {
      this.validateDob()
      this.$refs.form.validate()
      if (this.formValid === false || this.dobValid === 'Required') {
        return
      }
      this.$axios.post('v1/individual/verify', {
        token: this.$route.query.token,
        patient: this.patient
      })
        .then(async (response) => {
          await this.$store.dispatch('User/login', { email: response.data.email })
          event.emit(Events.SUCCESS, 'Verification successful!')
          await this.$router.push({ name: 'Dashboard' })
        })
        .finally(() => {
          this.loading = false
        })
    },
    async getFullName () {
      const response = await this.$axios.get('v1/individual/verify/' + this.$route.query.token)
      this.patient.full_name = response.data.payload.full_name
    }
  }
}
</script>
